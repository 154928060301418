import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from ".";
import { APiQueryBase, ApiResponseBase } from "../interfaces/ApiResponseBase";
import { ErrorResult } from "../interfaces/ErrorResult";
import { isErrorResult } from "../utils/api";

type WebhooksResponse = ApiResponseBase<any[]>;

export class WebhooksService {
  static async get({
    filter,
    page,
    order,
  }: APiQueryBase): Promise<WebhooksResponse | ErrorResult> {
    try {
      const response = await api.get<WebhooksResponse>(
        "/api/v2/webhook-public/configuration",
        {
          params: {
            page,
            per_page: 10,
            filter: JSON.stringify(filter),
            order: JSON.stringify(order),
          },
        }
      );
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResult>;
      return {
        success: false,
        message: axiosError.response?.data?.message || "Unknown error occurred",
      } as ErrorResult;
    }
  }
  static async listWebhooksTypes(): Promise<any | ErrorResult> {
    try {
      const response = await api.get<any>("/api/v2/webhook-public/type");

      const options: any = {
        options: [],
        hasMore: false,
        additional: {
          page: 1,
        },
      };

      if (isErrorResult(response)) return options;

      const data = response?.data?.data ?? response.data;

      //@ts-ignore
      options.options = data?.map((d) => ({
        label: `${d.name}`,
        value: d.id,
      }));
      console.log("options ->", options);
      return options;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResult>;
      return {
        success: false,
        message: axiosError.response?.data?.message || "Unknown error occurred",
      } as ErrorResult;
    }
  }
  static async listWebhooksLogs(
    id?: string | number
  ): Promise<any | ErrorResult> {
    try {
      if (!id) {
        return [];
      }
      const response = await api.get<any>(`/api/v2/webhook-public/${id}/logs`);

      if (isErrorResult(response)) return [];

      const data = response?.data?.data ?? response.data;
      return data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResult>;
      return {
        success: false,
        message: axiosError.response?.data?.message || "Unknown error occurred",
      } as ErrorResult;
    }
  }

  //@ts-ignore
  static async toggleWebhook({ id }): Promise<any | ErrorResult> {
    try {
      const response = await api.post<any>(
        `/api/v2/webhook-public/configuration/${id}/toogle-enabled`
      );

      return response?.data?.data ?? response?.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResult>;
      return {
        success: false,
        message: axiosError.response?.data?.message || "Unknown error occurred",
      } as ErrorResult;
    }
  }
}

export const useWebhooks = (
  query: APiQueryBase,
  options?: {
    enabled?: boolean;
    refetchInterval?: number | false;
    refetchIntervalInBackground?: boolean;
    refetchOnWindowFocus?: boolean;
    staleTime?: number;
    cacheTime?: number;
    retry?: boolean | number;
    retryDelay?: number | ((attemptIndex: number) => number);
    onSuccess?: (data: WebhooksResponse | ErrorResult) => void;
    onError?: (error: AxiosError<ErrorResult>) => void;
    select?: (data: WebhooksResponse | ErrorResult) => any;
    [key: string]: any;
  }
): UseQueryResult<WebhooksResponse | ErrorResult, AxiosError<ErrorResult>> => {
  return useQuery<WebhooksResponse | ErrorResult, AxiosError<ErrorResult>>(
    ["webhooks", query],
    () => WebhooksService.get(query),
    {
      ...options, // Spread das opções fornecidas
    }
  );
};

export const useWebhooksTypes = () => {
  return useQuery({
    queryKey: ["webhooksTypes"],
    queryFn: WebhooksService.listWebhooksTypes,
    staleTime: 1000 * 60 * 5, // Cache válido por 5 minutos
    cacheTime: 1000 * 60 * 30, // Cache armazenado por 30 minutos
  });
};
export const useWebhooksLogs = (
  id?: string | number,
  options?: UseQueryOptions<any, ErrorResult, any>
) => {
  return useQuery<any, ErrorResult>(
    ["webhookLogs", id],
    () => WebhooksService.listWebhooksLogs(id),
    {
      enabled: !!id, // Só executa se o ID estiver definido
      ...options,
    }
  );
};
