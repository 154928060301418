import React, { useState } from "react";
import { Button, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaEye, FaPen, FaPlus } from "react-icons/fa";
import { ActionButtonOutline } from "../../components/ActionButtonOutline";
import { toast } from "react-toastify";
import CustomThead from "../../components/CustomThead";

import { Container } from "./styles";

import { ProtectedComponent } from "../../components/ProtectedComponent";
import { module_names } from "../../constants/module_names";
import {
  WebhooksService,
  useWebhooks,
} from "../../core/services/WebhooksService";
import WebhookDetailModal from "../../components/WebhookModals/WebhookDetailModal"; // Importando a modal
import WebhookRegisterModal from "../../components/WebhookModals/WebhookRegisterModal";
import { useQueryClient } from "@tanstack/react-query";

const ConfigsWebHook: React.FC = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [selectedWebhook, setSelectedWebhook] = useState<any>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();

  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const { data, isLoading } = useWebhooks({
    filter: {},
    page,
    order: {},
  });

  const handleViewWebhook = (webhook: any) => {
    setSelectedWebhook(webhook);
    setShowModal(true);
  };
  const handleAddWebhook = () => {
    setSelectedWebhook(null);
    setIsEditing(false);
    setShowRegisterModal(true);
  };
  const handleEditWebhook = (webhook: any) => {
    setSelectedWebhook(webhook);
    setIsEditing(true);
    setShowRegisterModal(true);
  };
  const handleToggleWebhook = async (id: number) => {
    try {
      await WebhooksService.toggleWebhook({ id });

      toast.success(<div>{t("configs.webhook.actions.toggle_success")}</div>);
    } catch (error) {
      console.error(error);
      toast.error(<div>{t("configs.audit.actions.toggle_failure")}</div>);
    } finally {
      queryClient.invalidateQueries(["webhooks"]);
    }
  };
  const WebhookUrl: React.FC<{ url: string }> = ({ url }) => {
    const [isHovered, setIsHovered] = useState(false);

    const copyToClipboard = (text: string) => {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast.success(<div>{t("configs.webhook.url.copy.success")}</div>);
        })
        .catch((err) => {
          console.error("Erro ao copiar URL: ", err);
          toast.error(<div>t("configs.webhook.url.copy.error")</div>);
        });
    };

    const abbreviatedUrl = url?.length > 15 ? `${url.slice(0, 15)}...` : url; // Abreviação da URL

    return (
      <div
        className="position-relative d-inline-block"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* URL abreviada */}
        <span className="text-truncate">{abbreviatedUrl}</span>

        {/* Exibição da URL completa em um tooltip-like div */}
        {isHovered && (
          <div
            className="position-absolute bg-white border rounded shadow-sm p-1"
            style={{
              top: "100%",
              left: "0",
              zIndex: 1000,
              minWidth: "200px",
              maxWidth: "400px",
              whiteSpace: "nowrap",
            }}
          >
            <div className="d-flex  justify-content-between align-items-center">
              <span className="text-break">{url}</span>
            </div>

            <Button variant="link" onClick={() => copyToClipboard(url)}>
              {t("configs.webhook.url.copy")}
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <ProtectedComponent module_name={module_names.CONFIG_AUDIT} property="view">
      <Container className="mb-3">
        <Row className="mb-3">
          <Col className="d-flex align-items-center" xs="auto">
            <h5 className="mb-0 p-0">{t("configs.webhook.title")}</h5>
          </Col>
          <Col className="d-flex justify-content-end">
            <Button variant="primary" onClick={handleAddWebhook}>
              <FaPlus className="me-2" />
              {t("configs.webhook.actions.add")}
            </Button>
          </Col>
        </Row>
        <Row>
          {isLoading ? (
            <Spinner animation="border" />
          ) : (
            <Table striped bordered hover>
              <CustomThead
                data={[
                  {
                    column: "created_at",
                    name: t("configs.webhook.column.name"),
                  },
                  {
                    column: "user_name",
                    name: t("configs.webhook.column.status"),
                  },
                  {
                    column: "updated_at",
                    name: t("configs.webhook.column.method"),
                  },
                  {
                    column: "amount_events",
                    name: t("configs.webhook.column.type"),
                  },
                  {
                    column: "amount_events",
                    name: t("configs.webhook.column.url"),
                  },
                  {
                    column: "amount_events",
                    name: t("configs.webhook.column.actions"),
                  },
                ]}
                onChangeOrder={(order) => {
                  // setPagination((prev) => ({ ...prev, order }));
                }}
              />
              <tbody className="p-2">
                {/* @ts-ignore */}
                {data?.data?.map((webhook, index) => (
                  <tr key={index} className="align-middle">
                    <td className="p-2 text-truncate">{webhook.name}</td>
                    <td className="p-2 text-center">
                      <Form.Check
                        type="switch"
                        id={`enabled-${index}`}
                        checked={webhook.enabled === 1}
                        onChange={() => handleToggleWebhook(webhook.id)}
                      />
                    </td>
                    <td className="p-2 text-truncate">{webhook.method}</td>
                    <td className="p-2 text-center">
                      {webhook?.webhook_type || ""}
                    </td>
                    <td className="p-2 text-center">
                      <WebhookUrl url={webhook.url} />
                    </td>
                    <td className="p-2 text-center">
                      <div className="d-flex justify-content-center gap-2">
                        <ActionButtonOutline
                          onClick={() => handleViewWebhook(webhook)}
                          label={t("configs.audit.actions.view")}
                        >
                          <FaEye />
                        </ActionButtonOutline>
                        <ActionButtonOutline
                          onClick={() => handleEditWebhook(webhook)}
                          label={t("configs.audit.actions.edit")}
                        >
                          <FaPen />
                        </ActionButtonOutline>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Row>
        {selectedWebhook && (
          <WebhookDetailModal
            show={showModal}
            onHide={() => setShowModal(false)}
            webhook={selectedWebhook}
          />
        )}
        {showRegisterModal && (
          <WebhookRegisterModal
            show={showRegisterModal}
            onHide={() => setShowRegisterModal(false)}
            webhookId={isEditing ? selectedWebhook.id : undefined}
            initialData={isEditing ? selectedWebhook : undefined}
          />
        )}
      </Container>
    </ProtectedComponent>
  );
};

export default ConfigsWebHook;
