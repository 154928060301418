import React from "react";
import { Modal, Button, Spinner, Alert, Card, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useWebhooksLogs } from "../../../core/services/WebhooksService";
import { FaClipboard } from "react-icons/fa";

interface WebhookDetailModalProps {
  show: boolean;
  onHide: () => void;
  webhook: any;
}

const WebhookDetailModal: React.FC<WebhookDetailModalProps> = ({
  show,
  onHide,
  webhook,
}) => {
  const { t } = useTranslation();
  const { data, isLoading, isError, error } = useWebhooksLogs(webhook?.id);

  const copyToClipboard = (value: any) => {
    const textToCopy =
      typeof value === "object" && value !== null
        ? JSON.stringify(value, null, 2) // Converte objeto em string formatada
        : value?.toString(); // Para outros tipos, converte diretamente para string

    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy).then(
        () => {
          // alert(t("configs.webhook.modal.copied"));
        },
        (err) => {
          console.error("Failed to copy: ", err);
          // alert(t("configs.webhook.modal.copyError"));
        }
      );
    }
  };
  return (
    <Modal show={show} onHide={onHide} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{t("configs.webhook.modal.details")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Loading Indicator */}
        {isLoading && (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">{t("loading")}</span>
            </Spinner>
            <p>{t("configs.webhook.modal.loading")}</p>
          </div>
        )}

        {/* Error Message */}
        {isError && (
          <Alert variant="danger">
            {error?.message || t("configs.webhook.modal.error")}
          </Alert>
        )}

        {/* No Data Available */}
        {!isLoading && !data?.length && !isError && (
          <Alert variant="info">{t("configs.webhook.modal.noData")}</Alert>
        )}

        {/* Data Display */}
        {!isLoading &&
          data?.length > 0 &&
          data.map((item: any, index: number) => (
            <Card key={index} className="mb-4">
              <Card.Header>
                <strong>Log #{item?.id}</strong>
              </Card.Header>
              <Card.Body>
                <Table striped bordered hover responsive>
                  <tbody>
                    {Object.entries(item).map(([key, value]) => (
                      <tr key={key}>
                        <td>
                          <strong>{key}</strong>
                        </td>
                        <td>
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              {typeof value === "object" && value !== null
                                ? JSON.stringify(value, null, 2)
                                : value?.toString() ||
                                  t("configs.webhook.modal.noValue")}
                            </div>
                            <Button
                              variant="outline-secondary"
                              size="sm"
                              onClick={() => copyToClipboard(value)}
                              style={{ marginLeft: "10px" }}
                            >
                              <FaClipboard />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t("configs.webhook.modal.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WebhookDetailModal;
