import React, { useState, useEffect } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Row,
  Stack,
  Table,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PrivateMasterPage from "../../layout/PrivateMasterPage";
import { Container } from "./styles";
import { useReports } from "../../core/services/ReportsService";
import { format, parseISO } from "date-fns";
import {
  FaExclamationTriangle,
  FaFileAlt,
  FaFileArchive,
  FaFileCsv,
  FaFilePdf,
} from "react-icons/fa";
import { LoadingReportScreen } from "./LoadingReportsScreen";
import TableFooter from "../../components/TableFooter";
import { FileService } from "../../core/services/FileService";

import CustomSearchInput from "../../components/CustomSearchInput";
import CustomThead from "../../components/CustomThead";

type PaginationState = {
  page: number;
  pages: number;
  filter: Record<string, any>;
  order?: { [key: string]: string };
};
type StatusType = "requested" | "processing" | "success" | "fail";

interface Report {
  status: StatusType;
  description: string;
  created_at: string;
  completed_at: string;
  user_name: string;
  url?: string;
}
function formatDate(dateString: string): string {
  try {
    const parsedDate = parseISO(dateString);
    return format(parsedDate, "dd/MM/yyyy HH:mm");
  } catch {
    return dateString;
  }
}

const Reports: React.FC = () => {
  const { t } = useTranslation();
  const [showDownloadError, setShowDownloadError] = useState(false);
  const [pagination, setPagination] = useState<PaginationState>({
    page: 1,
    pages: 0,
    filter: {},
    order: { created_at: "desc" },
  });

  const { data, isLoading, error } = useReports(
    {
      filter: pagination.filter,
      page: pagination.page,
      order: pagination.order,
    },

    {
      staleTime: 1000 * 60 * 5, //5mins
      cacheTime: 1000 * 60 * 10, //10mins
    }
  );

  useEffect(() => {
    if (data?.meta) {
      setPagination((prev) => ({
        ...prev,
        pages: data.meta.last_page || 0,
      }));
    }
  }, [data]);

  const statusMap: Record<StatusType, { label: string; bg: string }> = {
    requested: { label: t("reports.status.requested"), bg: "info" },
    processing: { label: t("reports.status.processing"), bg: "info" },
    success: { label: t("reports.status.success"), bg: "success" },
    fail: { label: t("reports.status.fail"), bg: "danger" },
  };

  const handleOrderChange = (order: Record<string, string>) => {
    const [newKey] = Object.keys(order);
    const newOrder: Record<string, string> = {};

    if (pagination?.order && pagination.order.hasOwnProperty(newKey)) {
      newOrder[newKey] = pagination.order[newKey] === "asc" ? "desc" : "asc";
    } else {
      newOrder[newKey] = order[newKey];
    }

    setPagination((prev) => ({ ...prev, order: newOrder }));
  };

  const getFileNameFromUrl = (url: string) => {
    const urlParts = url.split("/");
    return urlParts[urlParts.length - 1];
  };

  const handleDownload = async (url: string | undefined) => {
    try {
      const element = document.createElement("a");

      if (url) {
        const processUrl = url.split(".com/");

        const file = await FileService.fetchFile(processUrl[1]);

        element.href = URL.createObjectURL(file.data);
        element.download = getFileNameFromUrl(url || "");
        document.body.appendChild(element);
        element.click();
      }
      document.body.removeChild(element);
    } catch (e) {
      console.error(e);
      setShowDownloadError(true);
    }
  };
  function onSearch(text: string): void {
    const filter = { ...pagination.filter, search: text };
    setPagination((prev) => ({ ...prev, filter }));
  }
  const isDownloadUrlValid = (url: string | null | undefined): boolean => {
    const extensionPattern = /\.[0-9a-z]+$/i;
    return url ? extensionPattern.test(url) : false;
  };

  const renderIcon = (url: string | null | undefined) => {
    const iconSize = 24;
    if (!url || !isDownloadUrlValid(url)) {
      return (
        <FaExclamationTriangle
          size={iconSize}
          style={{ color: "red" }}
          title="url inválida"
        />
      );
    }
    if (url.endsWith(".csv")) {
      return <FaFileCsv size={iconSize} />;
    }
    if (url.endsWith(".pdf")) {
      return <FaFilePdf size={iconSize} />;
    }
    if (url.endsWith(".zip")) {
      return <FaFileArchive size={iconSize} />;
    }
    return <FaFileAlt size={iconSize} />;
  };

  return (
    <PrivateMasterPage
      title={t("reports.title")}
      breadcrumb={[t("reports.title")]}
    >
      <Container fluid className="p-3">
        <Row className="mb-4">
          <Col></Col>

          <Col className="filter-col">
            <Stack direction="horizontal" gap={2}>
              <CustomSearchInput
                placeholder={t("search")}
                onChange={(e) => onSearch(e.target.value)}
              />
            </Stack>
          </Col>
        </Row>
        {showDownloadError && (
          <Alert
            variant="danger"
            onClose={() => setShowDownloadError(false)}
            dismissible
          >
            {t("reports.downloadError")}
          </Alert>
        )}
        <Card className="mb-5">
          <Card.Body className="p-4">
            {isLoading ? (
              <LoadingReportScreen />
            ) : error ? (
              <Alert variant="danger">{t("reports.loadError")}</Alert>
            ) : (
              <Table responsive hover>
                <CustomThead
                  data={[
                    {
                      column: "description",
                      name: t("reports.description"),
                      isSortable: false,
                    },
                    { column: "created_at", name: t("reports.created_at") },
                    { column: "completed_at", name: t("reports.completed_at") },
                    { column: "user_name", name: t("reports.user_id") },
                    { column: "status", name: t("reports.status") },
                    {
                      column: "",
                      name: t("reports.download"),
                      isSortable: false,
                    },
                  ]}
                  onChangeOrder={handleOrderChange}
                />
                <tbody>
                  {/* @ts-ignore */}
                  {data?.data?.map((report: Report, index) => (
                    <tr key={`report_${index}`} style={{ maxHeight: "8px" }}>
                      <td>{report.description}</td>
                      <td>{formatDate(report.created_at)}</td>
                      <td>{formatDate(report.completed_at)}</td>
                      <td>{report.user_name}</td>
                      <td className="text-center align-middle p-0">
                        <Badge bg={statusMap[report?.status]?.bg || "danger"}>
                          {statusMap[report?.status]?.label || "Erro"}
                        </Badge>
                      </td>

                      <td className="text-center align-middle p-0">
                        {report?.url && isDownloadUrlValid(report?.url) ? (
                          <Button
                            variant="outline-primary"
                            onClick={() => handleDownload(report.url)}
                            title={report.description} // Mostra a descrição ao passar o mouse
                            style={{ border: "none" }}
                          >
                            {renderIcon(report?.url)}
                          </Button>
                        ) : (
                          <div>
                            {" "}
                            {report?.status === "fail" &&
                              renderIcon(report.url)}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
        <TableFooter
          paginate={{
            current_page: pagination.page,
            qtd: pagination.pages,
            onSelectPage: (page) =>
              setPagination((prev) => ({ ...prev, page })),
          }}
        />
      </Container>
    </PrivateMasterPage>
  );
};

export default Reports;
