import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";
import ConfigsMenu from "../../components/ConfigsMenu";
import PrivateMasterPage from "../../layout/PrivateMasterPage";
import ConfigsAPI from "../ConfigsAPI";
import ConfigsAudit from "../ConfigsAudit";
import ConfigsCompany from "../ConfigsCompany";
import ConfigsContractors from "../ConfigsContractors";
import ConfigsDriverApp from "../ConfigsDriverApp";
import ConfigsExitLocations from "../ConfigsExitLocations";
import FleetMateConfigs from "../ConfigsFleetMate";
import FleetMateForm from "../ConfigsFleetMate/EmbeddedIntelligenceForm";
import Notification from "../ConfigsNotifications/Notification";
import NotificationForm from "../ConfigsNotifications/NotificationForm";
import ConfigsRouting from "../ConfigsRouting";
import ConfigsSubContractors from "../ConfigsSubContractors";
import Users from "../ConfigsUsers";
import { Card, Container } from "./styles";
import RulesList from "../ConfigsFleetMate/RulesList";
import { ConfigsBilling } from "../ConfigsBilling";
import { ProtectedComponent } from "../../components/ProtectedComponent";
import { module_names } from "../../constants/module_names";

import ConfigsWebHook from "../ConfigsWebHook";

const Configs: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PrivateMasterPage
      title={t("configs.title")}
      breadcrumb={[t("configs.title")]}
    >
      <div className="p-3 w-100">
        <Card>
          <Card.Body className="p-0">
            <Container className="h-100">
              <Row className="h-100">
                <Col className="side-menu" xs="auto">
                  <ConfigsMenu />
                </Col>
                <Col className="config-content" xs>
                  <Routes>
                    <Route
                      path="/company"
                      element={
                        <ProtectedComponent
                          module_name={module_names.CONFIG_COMPANY}
                          property="view"
                          fallbackComponent={
                            <Navigate to={"/configs/billing"} />
                          }
                        >
                          <ConfigsCompany />
                        </ProtectedComponent>
                      }
                    />
                    <Route
                      path="/billing"
                      element={
                        <ProtectedComponent
                          module_name={module_names.CONFIG_BILLING}
                          property="view"
                          fallbackComponent={
                            <Navigate to={"/configs/exit-locations"} />
                          }
                        >
                          <ConfigsBilling />
                        </ProtectedComponent>
                      }
                    />
                    <Route
                      path="/exit-locations"
                      element={
                        <ProtectedComponent
                          module_name={module_names.CONFIG_EXIT_LOCATION}
                          property="view"
                        >
                          <ConfigsExitLocations />
                        </ProtectedComponent>
                      }
                    />
                    <Route path="/app" element={<ConfigsDriverApp />} />
                    <Route
                      path="/users"
                      element={
                        <ProtectedComponent
                          module_name={module_names.CONFIG_USER}
                          property="view"
                          fallbackComponent={<Navigate to={"/configs/app"} />}
                        >
                          <Users />
                        </ProtectedComponent>
                      }
                    />
                    <Route
                      path="/contractors"
                      element={<ConfigsContractors />}
                    />
                    <Route
                      path="/sub-contractors"
                      element={<ConfigsSubContractors />}
                    />
                    <Route path="/mate">
                      <Route
                        index
                        element={
                          <ProtectedComponent
                            module_name={module_names.FLEETMATE}
                            property="view"
                            fallbackComponent={<Navigate to={"/configs/api"} />}
                          >
                            <FleetMateConfigs />
                          </ProtectedComponent>
                        }
                      />
                      <Route path=":id/new-rule" element={<FleetMateForm />} />
                      <Route path="new-group" element={<RulesList />} />

                      <Route path="edit" element={<FleetMateForm />} />
                      <Route path="edit-config" element={<FleetMateForm />} />
                      <Route path=":id" element={<RulesList />} />
                    </Route>
                    <Route
                      path="/api"
                      element={
                        <ProtectedComponent
                          module_name={module_names.CONFIG_API}
                          property="view"
                          fallbackComponent={<Navigate to={"/configs/audit"} />}
                        >
                          <ConfigsAPI />
                        </ProtectedComponent>
                      }
                    />
                    <Route path="/sms">
                      <Route path="new" element={<NotificationForm />} />
                      <Route index element={<Notification />} />
                    </Route>
                    <Route
                      path="/routing"
                      element={
                        <ProtectedComponent
                          module_name={module_names.CONFIG_ROUTING}
                          property="view"
                        >
                          <ConfigsRouting />
                        </ProtectedComponent>
                      }
                    />

                    <Route
                      path="/audit"
                      element={
                        <ProtectedComponent
                          module_name={module_names.CONFIG_AUDIT}
                          property="view"
                        >
                          <ConfigsAudit />{" "}
                        </ProtectedComponent>
                      }
                    />
                    <Route
                      path="/webhooks"
                      element={
                        <ProtectedComponent
                          module_name={module_names.CONFIG_AUDIT}
                          property="view"
                        >
                          <ConfigsWebHook />{" "}
                        </ProtectedComponent>
                      }
                    />
                  </Routes>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </div>
    </PrivateMasterPage>
  );
};

export default Configs;
