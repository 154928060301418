import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import { useForm, useFieldArray, Controller } from "react-hook-form";

import "react-toastify/dist/ReactToastify.css";
import { FaTimesCircle } from "react-icons/fa";
import { WebhooksService } from "../../core/services/WebhooksService";
import { AsyncPaginate } from "react-select-async-paginate";
import { useTranslation } from "react-i18next";
import { api } from "../../core/services";
import { useQueryClient } from "@tanstack/react-query";
import { updateAlert } from "../../core/redux/reducer/alert";
import { useAppDispatch } from "../../core/redux/hooks";

interface HeaderEntry {
  key: string;
  value: string;
}

interface WebhookFormData {
  webhook_type_id: number | null;
  name: string;
  method: string;
  url: string;
  enabled: boolean;
  header: HeaderEntry[];
}

interface WebhookRegisterModalProps {
  show: boolean;
  onHide: () => void;
  webhookId?: number;
  initialData?: WebhookFormData;
}

const WebhookRegisterModal: React.FC<WebhookRegisterModalProps> = ({
  show,
  onHide,
  webhookId,
  initialData,
}) => {
  const { control, handleSubmit, register, reset } = useForm<WebhookFormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "header",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const [webhookTypeOptions, setWebhookTypeOptions] = useState<any[]>([]);
  useEffect(() => {
    if (webhookId && initialData) {
      // Formatar o webhook_type_id para o formato de opção
      const formattedData = {
        ...initialData,
        webhook_type_id: initialData.webhook_type_id
          ? {
              value: initialData.webhook_type_id,
              //@ts-ignore
              label: `${initialData.webhook_type}`,
            }
          : null,
      };
      //@ts-ignore
      reset(formattedData);
    } else {
      reset({
        webhook_type_id: null,
        name: "",
        method: "POST",
        url: "",
        enabled: true,
        header: [{ key: "", value: "" }],
      });
    }
  }, [webhookId, initialData, reset]);

  const onSubmit = async (data: WebhookFormData) => {
    setIsLoading(true);

    //@ts-ignore
    const payload = { ...data, webhook_type_id: data?.webhook_type_id?.value };
    try {
      if (webhookId) {
        await api.put(
          `/api/v2/webhook-public/configuration/${webhookId}`,
          payload
        );

        dispatch(
          updateAlert({
            title: t("configs.webhook.actions.add.success"),
          })
        );
      } else {
        await api.post("/api/v2/webhook-public/configuration", payload);

        dispatch(
          updateAlert({
            title: t("configs.webhook.actions.update.success"),
          })
        );
      }
      onHide();
    } catch (error) {
      console.error(error);
      dispatch(
        updateAlert({
          title: t(
            webhookId
              ? "configs.webhook.actions.update.fail"
              : "configs.webhook.actions.add.success"
          ),
          type: "error",
        })
      );
    } finally {
      setIsLoading(false);
      queryClient.invalidateQueries(["webhooks"]);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {webhookId ? "Editar Webhook" : "Configurar Webhook"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3">
            <Form.Label>{t("configs.webhook.modal.type")}</Form.Label>
            <Controller<WebhookFormData>
              control={control}
              name="webhook_type_id"
              render={({ field: { value, onChange, onBlur } }) => (
                <AsyncPaginate
                  isMulti={false}
                  placeholder={t("input.select_text")}
                  loadOptions={(inputValue, options, additional) =>
                    WebhooksService.listWebhooksTypes()
                  }
                  defaultOptions={webhookTypeOptions}
                  value={value}
                  onChange={(selectedOption) => onChange(selectedOption)}
                  onBlur={onBlur}
                />
              )}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("configs.webhook.modal.name")}</Form.Label>
            <Form.Control
              type="text"
              {...register("name", { required: true })}
              placeholder={t("configs.webhook.modal.name.placeholder")}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("configs.webhook.modal.method")}</Form.Label>
            <Controller
              name="method"
              control={control}
              defaultValue="POST"
              render={({ field }) => (
                <Form.Select {...field}>
                  <option value="POST">POST</option>
                  <option value="GET">GET</option>
                </Form.Select>
              )}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("configs.webhook.modal.url")}</Form.Label>
            <Controller
              name="url"
              control={control}
              defaultValue=""
              rules={{
                required: "A URL é obrigatória.",
                pattern: {
                  value:
                    /^(https?:\/\/)(localhost|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))(:\d+)?(\/.*)?$/i,
                  message:
                    "Insira uma URL válida (ex: https://example.com ou http://localhost:4000).",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Form.Control
                    type="url"
                    {...field}
                    placeholder="https://example.com/webhook"
                    isInvalid={!!error}
                  />
                  {error && (
                    <Form.Control.Feedback type="invalid">
                      {error.message}
                    </Form.Control.Feedback>
                  )}
                </>
              )}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("configs.webhook.modal.headers")}</Form.Label>
            {!fields && <br />}
            {fields &&
              fields?.map((field, index) => (
                <Row key={field.id} className="mb-2 align-items-center">
                  <Col>
                    <Form.Control
                      placeholder={t("configs.webhook.modal.headers.key")}
                      {...register(`header.${index}.key`, { required: true })}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      placeholder={t("configs.webhook.modal.headers.value")}
                      {...register(`header.${index}.value`, { required: true })}
                    />
                  </Col>
                  <Col xs="auto">
                    <Button variant="danger" onClick={() => remove(index)}>
                      <FaTimesCircle />
                    </Button>
                  </Col>
                </Row>
              ))}
            <p>
              <Button
                variant="secondary"
                onClick={() => append({ key: "", value: "" })}
              >
                {t("configs.webhook.modal.headers.add")}
              </Button>
            </p>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              id="enabled-checkbox"
              type="checkbox"
              {...register("enabled")}
              label={t("configs.webhook.modal.active")}
            />
            {/* <Form.Label htmlFor="enabled-checkbox" className="ms-2">
              {t("configs.webhook.modal.active")}
            </Form.Label> */}
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button variant="secondary" onClick={onHide} className="me-2">
              {t("configs.webhook.modal.cancel")}
            </Button>
            <Button variant="primary" type="submit" disabled={isLoading}>
              {isLoading
                ? t("configs.webhook.modal.saving")
                : t("configs.webhook.modal.save")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default WebhookRegisterModal;
